import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { useRouter } from 'next/router';
import { isMobile, isTablet } from 'react-device-detect';

interface LuxuryButtonProps extends ButtonProps {
  text?: string;
  url?: string;
  onClick?: () => void;
  customStyles?: React.CSSProperties; // Add this line
}

const StyledLuxuryButton = styled(Button)<LuxuryButtonProps>(({ customStyles = {} }) => ({
  fontWeight: 600,
  padding: '1rem 1.5rem',
  borderRadius: '0.5rem',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  transition: 'all 0.3s ease',
  backdropFilter: 'blur(10px)',
  // backgroundColor: 'rgba(255, 255, 255, 0.2)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.2)',
  },
  ...customStyles,
}));

const LuxuryButton: React.FC<LuxuryButtonProps> = ({ text, url, onClick, ...rest }) => {
  const isMobileOrIsTablet = isMobile || isTablet;
  const router = useRouter();
  const handleClick = () => {
    if (url) {
      router.push(url, undefined, { shallow: true });
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <StyledLuxuryButton
      variant="contained"
      color={rest?.color || 'primary'}
      onClick={handleClick}
      {...rest}
      sx={{ fontSize: isMobileOrIsTablet ? '0.8rem' : '1.1rem', py: 1.5, pointerEvents: 'all', ...rest.sx }}
    >
      {text}
    </StyledLuxuryButton>
  );
};

export default LuxuryButton;
